//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    text: {
      type: String,
      default: 'homepage-topfooter-title'
    },
    btnText: {
      type: String,
      default: 'homepage-topfooter-btn'
    }
  }
}
