//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FooterTop from '@/components/v3-components/footer-top.vue'
import HeroSection from '@/components/v3-components/hero-section.vue'
import HomeCardLink from '@/components/v3-components/HomeCardLink.vue'
import IndustriesCarousel from '@/components/v3-components/industries-carousel.vue'
import StepsCarousel from '@/components/v3-components/StepsCarousel.vue'
// import Newsletter from '@/components/v3-components/newsletter.vue'
import PreFooterArea from '@/components/v3-components/pre-footer.vue'

const lg = '(min-width: 1024px)'
const md = '(max-width: 1023.99px) and (min-width: 501px)'
const sm = '(max-width: 500.99px)'

export default {
  components: {
    HeroSection,
    FooterTop,
    StepsCarousel,
    HomeCardLink,
    // Newsletter,
    PreFooterArea,
    IndustriesCarousel
  },
  data () {
    return {
      interval: null,
      windowWidth: typeof window !== 'undefined' ? window.innerWidth : null,
      isPC:
        typeof window !== 'undefined' ? window.matchMedia(lg).matches : false,
      isTablet:
        typeof window !== 'undefined' ? window.matchMedia(md).matches : false,
      isMobile:
        typeof window !== 'undefined' ? window.matchMedia(sm).matches : false
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    clearTimeout(this.interval)
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      clearTimeout(this.interval)
      this.interval = setTimeout(() => {
        this.windowWidth = window.innerWidth
        this.isPC = window.matchMedia(lg).matches
        this.isTablet = window.matchMedia(md).matches
        this.isMobile = window.matchMedia(sm).matches
      }, 500)
    },
    visibilityChanged (isVisible, entry) {
      if (isVisible) {
        const element = entry.target
        element.classList.add('animation-pull-up-active')
      } else {
        const element = entry.target
        element.classList.remove('animation-pull-up-active')
      }
    }
  }
}
